<template>
  <div class="particles">
    <div class="particleGradient"></div>
    <div id="tsparticles" class="particles"></div>
  </div>
</template>

<script>
import { tsParticles } from 'tsparticles/tsparticles.slim.min.js'

import particlesConfig from './particles.json'

export default {
  name: 'particles',
  data() {
    return {
      particlesConfig,
    }
  },
  mounted() {
    this.initParticles()
  },
  methods: {
    initParticles() {
      tsParticles.load('tsparticles', particlesConfig)
    },
  },
}
</script>

<style scoped>
#tsparticles {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

.particleGradient {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: radial-gradient(
    at 100% 100%,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 30%,
    rgba(255, 255, 255, 1) 50%
  ) !important;
}
</style>
