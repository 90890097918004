<template>
  <div
    id="burger"
    :class="isBurgerActive ? 'active' : 'inactive'"
    @click.prevent="toggle"
  >
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
        <span class="burger-bar burger-bar--4"></span>
      </button>
    </slot>
  </div>
</template>

<script>
import { store, mutations } from '@/store/index.js'
export default {
  computed: {
    isBurgerActive() {
      return store.isNavOpen
    },
  },
  methods: {
    toggle() {
      mutations.toggleNav()
    },
  },
}
</script>

<style>
.hidden {
  visibility: hidden;
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.burger-button {
  position: relative;
  height: 30px;
  width: 32px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: #130f40;
  position: absolute;
  top: 50%;
  right: 6px;
  left: 6px;
  height: 2px;
  width: auto;
  margin-top: -1px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* burger standard state */
.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(0.75);
}

.burger-bar--3 {
  transform: translateY(6px) scaleX(0.5);
  transform-origin: 100% 0%;
}

.burger-bar--4 {
  transform: scaleX(0.5) translateY(12px);
  transform-origin: 100% 0%;
}

/* burger hover state */
.burger-button:hover .burger-bar--2 {
  transform: scaleX(1);
}

#burger.inactive .burger-button:hover .burger-bar--3 {
  transform: translate(-11px, -11px) rotate(-70deg) scaleX(1);
}

.burger-button:hover .burger-bar--4 {
  transform: translate(-4px, -11px) rotate(-70deg) scaleX(1);
}

/* burger active state */
#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg) translate(0px, 3px);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg) translate(0px, -6px);
}

#burger.active .burger-bar--4 {
  opacity: 0;
}
</style>
