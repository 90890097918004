<template>
  <div class="app d-flex flex-column overflow-hidden min-vh-100 vh-100">
    <header>
      <nav class="navbar" style="">
        <Burger></Burger>
        <div class="brandndame text-lowercase" v-if="isHome">
          <h4>mark.crowe</h4>
        </div>
      </nav>
    </header>
    <body class="bg-transparent">
      <main role="main" class="flex-grow-1">
        <Sidebar>
          <ul class="nav nav-pills flex-column mb-auto text-lowercase">
            <li class="nav-item">
              <router-link
                to="/"
                class="nav-link link-dark"
                @click="closeSidebarPanel"
              >
                <img :src="mdiCodeJson" class="me-2" />
                home
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/about"
                class="nav-link link-dark"
                @click="closeSidebarPanel"
              >
                <img :src="mdiCodeJson" class="me-2" />
                about
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/milestones"
                class="nav-link link-dark"
                @click="closeSidebarPanel"
              >
                <img :src="mdiCodeJson" class="me-2" />
                milestones
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/skills"
                class="nav-link link-dark"
                @click="closeSidebarPanel"
              >
                <img :src="mdiCodeJson" class="me-2" />
                skills
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/projects"
                class="nav-link link-dark"
                @click="closeSidebarPanel"
              >
                <img :src="mdiCodeJson" class="me-2" />
                projects
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/contact"
                class="nav-link link-dark"
                @click="closeSidebarPanel"
              >
                <img :src="mdiCodeJson" class="me-2" />
                contact
              </router-link>
            </li>
          </ul>
        </Sidebar>
        <transition name="fade">
          <div class="container flex-fill foreground">
            <div class="row">
              <div class="col" v-if="windowWidth > 520"></div>
              <div :class="setColumnsMobile">
                <particles />
                <router-view />
              </div>
              <div class="col" v-if="windowWidth > 520"></div>
            </div>
          </div>
        </transition>
      </main>
    </body>
  </div>
</template>

<script>
import Burger from '@/components/common/Burger.vue'
import Sidebar from './components/common/Sidebar.vue'

import mdiCodeJson from '@/assets/material-design-icons/code-json.svg'

import particles from '@/components/particles/particlesBottomRight'

export default {
  name: 'app',
  mixins: [Sidebar],
  setup() {
    return {
      mdiCodeJson,
    }
  },
  data() {
    return {
      posts: [],
      windowWidth: window.innerWidth,
    }
  },
  components: {
    Burger,
    Sidebar,
    particles,
  },
  computed: {
    isHome() {
      return this.$route.name !== 'Home'
    },
    setColumnsMobile() {
      if (this.windowWidth < 540) return 'col-12'
      return 'col-8'
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.closeSidebarPanel()
  },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}

::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

#element {
  scrollbar-width: none;
}
html {
  height: 100vh;
  background: white;
}
.navbar {
  display: flex;
  position: sticky;
  top: 0;
  height: 50px;
  margin-left: 1rem;
  margin-right: 1rem;
  z-index: 999;
}
main {
  margin-left: 1rem;
  margin-right: 1rem;
}
.nav-link {
  color: black;
}

.fade-enter-active {
  animation: fade-in 1s;
}

.fade-leave-active {
  animation: fade-in 1s reverse;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
